<template>
  <v-app>
    <v-snackbar v-model="snackbar">
      <v-icon class="primary--text pa-1">mdi-alert</v-icon>
      <span class="white--text">Mauvais e-mail ou mot de passe. Veuillez réessayer.</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col style="margin-top: -100px" cols="12" sm="8" md="6">
          <v-card :loading="loading" class="opa elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Réinitialiser le mot de passe</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon large target="_blank" v-on="on">
                    <v-icon>mdi-lock-reset</v-icon>
                  </v-btn>
                </template>
                <span class="white--text">Modifier le mot de passe</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="valid" ref="form" :lazy-validation="false">
                <v-text-field id="email" v-model="email" :readonly="true" class="required" label="E-mail" name="email"
                  prepend-icon="mdi-email" type="email"></v-text-field>
                <!-- <v-text-field id="password" :rules="nameRules" v-model="oldpassword" class="required"
                  label="Ancien mot de passe" name="password" prepend-icon="mdi-lock" type="password"></v-text-field> -->
                <v-text-field id="password" :rules="nameRules" v-model="password" class="required"
                  label="Nouveau mot de passe" name="password" prepend-icon="mdi-lock" type="password"
                  @keyup.enter="login"></v-text-field>
                <v-text-field id="password" :rules="nameRules" v-model="repassword" class="required"
                  label="Confirmer votre mot de passe" name="password" prepend-icon="mdi-lock" type="password"
                  @keyup.enter="login"></v-text-field>
                <v-row>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center align-center center">
              <v-col>
                <v-btn type="submit" :disabled="!valid" @click="reset" color="primary">
                  <v-icon>mdi-lock</v-icon>Réinitialiser le mot de passe
                </v-btn>
              </v-col>

            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>


<script>
import xhr from "../plugins/axios";
import { initializeApp } from 'firebase/app';
import 'firebase/compat/auth';


export default {
  name: "ResetPassword",
  data() {
    return {
      snackbar: false,
      loading: false,
      valid: true,
      user: null,
      email: "",
      id: "",
      oldpassword: "",
      repassword: "",
      password: "",
      nameRules: [
        (v) => !!v || "Mot de passe obligatoire",
        (v) =>
          (v && v.length >= 8) ||
          "Mot de passe doit faire au moins 8 caractères",
      ],
      emailRules: [
        (v) => !!v || "E-mail obligatoire",
        (v) => /.+@.+\..+/.test(v) || "E-mail doit être valide",
      ],
    };
  },
  async created() {
    let email = this.$route.query.email
    let id = this.$route.query.id
    let token = this.$route.query.token

    let user = await xhr.post('/get-user', {
      email,
      token,
      id
    })
    this.user = user.data
    this.email = this.user.email
    console.log(" this.user ", this.user);
  },
  methods: {
    reset() {
      this.loading = true;
      let token = this.$route.query.token
      xhr
        .post("/reset-password", { email: this.email, token, password: this.password })
        .then((res) => {
          if (!res.data || res.data === null || res.data.success === false) {
            this.snackbar = true;
            this.snackbar = true;
            this.loading = false;
          } else if (res.data.success === true) {
            this.$router.push('/login')
          }
        });
    },
  },
};
</script>